// biome-ignore lint/style/useNodejsImportProtocol: exposed in the ui, node is not, this requires some works
import { format as formatUrl } from 'url'
import { excludeSearchWords } from '@blissbook/common/search'
import { sanitizeHtml } from '@blissbook/lib/sanitize'
import { colors, gridBreakpoints } from '@blissbook/ui/branding'
import {
  transitionDuration,
  transparentInput,
} from '@blissbook/ui/dashboard/components'
import {
  HandbookNodeType,
  type HandbookSearchResult,
  type TextSearchResult,
} from '@blissbook/ui/dashboard/graph'
import { useHandbooks } from '@blissbook/ui/dashboard/hooks'
import { Button, SearchInput } from '@blissbook/ui/lib'
import { css } from '@emotion/react'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import pickBy from 'lodash/pickBy'
import type React from 'react'
import { useState } from 'react'
import { searchHandbooks } from './queries'

const textWithHighlight = (originalText: string, textToHighlight?: string) => {
  if (!textToHighlight) return originalText
  const strRegExp = new RegExp(`(${textToHighlight})`, 'gi')
  return originalText.replace(strRegExp, '<strong>$1</strong>')
}

const nodeTypeToLabel: Record<HandbookNodeType, string> = {
  [HandbookNodeType.DocumentRef]: 'Policy',
  [HandbookNodeType.HandbookSection]: 'Section',
}

const mapResultToOption = (
  result: HandbookSearchResult,
  {
    handbooks,
    index,
    search,
  }: {
    handbooks: IHandbook[]
    index: number
    search: string
  },
): {
  children: JSX.Element
  key: number
  url: string
} => {
  const { handbookId, node } = result

  // Must have a handbook
  const handbook = handbooks.find((h) => h.id === handbookId)
  if (!handbook) return

  // Calculate the url
  const url = formatUrl({
    hash: node?.hash,
    pathname: `/d/${handbookId}-${handbook.slug}`,
    query: pickBy({
      language: node?.languageCode,
      search,
    }),
  })

  return {
    children: !node ? (
      <SearchResult highlight={search} title={handbook.name} type='doc' />
    ) : !node.texts ? (
      <SearchResult
        path={handbook.name}
        highlight={search}
        title={node.title}
        type={nodeTypeToLabel[node.type]}
      />
    ) : (
      <SearchResult
        path={handbook.name}
        texts={node.texts}
        title={node.title}
        type='text'
      />
    ),
    key: index,
    url,
  }
}

type SearchResultProps = {
  highlight?: string
  path?: string
  texts?: TextSearchResult[]
  title: string
  type: string
}

const SearchResultPath: React.FC<{ path: string }> = ({ path }) =>
  path ? (
    <>
      <span>{path}</span>
      <FontAwesomeIcon className='tw-mx-2' icon={['far', 'angle-right']} />
    </>
  ) : null

const SearchResultTitle: React.FC<{
  highlight?: string
  title: string
}> = ({ highlight, title }) => (
  <span
    // biome-ignore lint/security/noDangerouslySetInnerHtml: filtered manually
    dangerouslySetInnerHTML={{
      __html: sanitizeHtml(textWithHighlight(title, highlight)),
    }}
  />
)

const SearchResultTexts: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    texts: TextSearchResult[]
  }
> = ({ texts, ...props }) =>
  texts ? (
    <div {...props}>
      {texts.map((text, index) => (
        <span className='tw-mr-2' key={index}>
          ...{text.before}
          <strong>{text.match}</strong>
          {text.after}...
        </span>
      ))}
    </div>
  ) : null

const SearchResult: React.FC<SearchResultProps> = ({
  highlight,
  path,
  texts,
  title,
  type,
}) => (
  <div
    className='tw-flex tw-items-center'
    css={{
      whiteSpace: 'normal',
    }}
  >
    <div
      className='text-center'
      css={{
        fontSize: 10,
        width: 64,
        textTransform: 'uppercase',
        [`@media (max-width: ${gridBreakpoints.lg}px)`]: {
          fontSize: 8,
          width: 53,
        },
      }}
    >
      {type}
    </div>

    <div style={{ flex: 1, minWidth: 0 }}>
      <div className='tw-hidden lg:tw-block tw-mb-1' css={{ fontSize: 18 }}>
        <SearchResultPath path={path} />
        <SearchResultTitle highlight={highlight} title={title} />
      </div>

      <div className='lg:tw-hidden tw-text-gray-600' css={{ fontSize: 10 }}>
        <SearchResultPath path={path} />
      </div>

      <div className='lg:tw-hidden' css={{ fontSize: 12 }}>
        <SearchResultTitle highlight={highlight} title={title} />
      </div>

      <SearchResultTexts
        className='tw-text-gray-600'
        css={{
          fontSize: 14,
          [`@media (max-width: ${gridBreakpoints.lg}px)`]: {
            fontSize: 10,
          },
        }}
        texts={texts}
      />
    </div>
  </div>
)

const desktopInputCss = css`
  .form-control {
    background: ${transparentInput.backgroundColor};
    border: none;
    color: ${colors.white};
    font-size: 18px;
    font-weight: 300;
    padding: 18px 20px;
    transition: background ${transitionDuration} linear,
      color ${transitionDuration} linear;
    &:not(.focus) {
      color: ${colors.white};
      input::placeholder {
        color: ${colors.white};
      }
      .search-icon,
      .clear-icon {
        color: ${colors.white};
      }
    }
    &:hover {
      background: ${transparentInput.backgroundHoverColor};
    }
    &.focus {
      background: ${colors.white};
      color: ${colors.black};
    }
  }
  .search-icon {
    font-size: 24px;
    margin-right: 20px;
    transition: all ${transitionDuration} linear;
  }
  input::placeholder {
    transition: all ${transitionDuration} linear;
  }
  .clear-icon {
    font-size: 32px;
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  &.open .form-control {
    border-bottom-left-radius: unset !important;
    border-bottom-right-radius: unset !important;
  }
`

const desktopDropdownCss = css`
  padding: 0;
  margin: 0;
  border: none;
  border-top-left-radius: unset;
  border-top-right-radius: unset;

  .dropdown-item {
    padding-left: 0;
  }
  .dropdown-item,
  .dropdown-item-text {
    border-bottom: 1px solid ${colors['gray-200']};
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
`

const mobileBtnCss = css`
  background: ${transparentInput.backgroundColor};
  border: none;
  color: ${colors.white};
  padding: 0.75rem 0.75rem;
  transition: all ${transitionDuration} linear;
  &:not(:disabled)&:not(.disabled) {
    &:active,
    &:hover,
    &:focus {
      background: ${transparentInput.backgroundHoverColor};
      color: white;
    }
  }
`

const mobileInputCss = css`
  .back-icon {
    font-size: 0.75rem;
    margin-right: 10px;
  }
  .dropdown-item {
    padding-left: 0;
  }
  .dropdown-item,
  .dropdown-item-text {
    border-bottom: 1px solid ${colors['gray-200']};
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }
`

export const SearchBar: React.FC = (props) => {
  const handbooks = useHandbooks()
  const [isMobileOpen, setMobileOpen] = useState(false)
  const [search, setSearch] = useState(null)
  const searchIcon = ['far', 'search'] as IconProp

  const getSearchResults = async (search: string): Promise<any> => {
    // If the search term has been deemed "invalid", show no results
    if (excludeSearchWords.includes(search)) return []

    // Go get the results
    const results = await searchHandbooks(search)
    return results
      .map((result, index) =>
        mapResultToOption(result, { handbooks, index, search }),
      )
      .filter(Boolean)
  }

  return (
    <>
      <SearchInput
        {...props}
        className='tw-hidden lg:tw-block'
        clearIcon={['fal', 'times']}
        css={desktopInputCss}
        debounce={500}
        dropdownClassName={desktopDropdownCss}
        dropdownMaxHeight={400}
        dropdownOffset={0}
        getOptions={getSearchResults}
        minLength={3}
        noResults='No results found'
        onChangeValue={setSearch}
        placeholder='Search all documents...'
        title='Search all documents'
        searchIcon={searchIcon}
        size='lg'
        value={search}
      />

      {!isMobileOpen ? (
        <Button
          {...props}
          className='btn-block lg:tw-hidden tw-flex tw-items-center'
          css={mobileBtnCss}
          color='input'
          onClick={() => {
            setMobileOpen(true)
          }}
        >
          <FontAwesomeIcon
            css={{ fontSize: 18, marginRight: 10 }}
            icon={searchIcon}
          />
          Search All..
        </Button>
      ) : (
        <SearchInput
          autoFocus
          backIcon='arrow-left'
          backIconColor='gray-600'
          className='lg:tw-hidden'
          css={mobileInputCss}
          clearIcon='times-circle'
          clearIconColor='gray-300'
          debounce={1000}
          fullScreen
          getOptions={getSearchResults}
          minLength={3}
          noResults='No results found'
          onBack={() => {
            setMobileOpen(false)
          }}
          onBlur={() => {
            if (!search) setMobileOpen(false)
          }}
          onChangeValue={setSearch}
          placeholder='Search...'
          title='Search'
          size='lg'
          value={search}
        />
      )}
    </>
  )
}
