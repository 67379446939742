import { gql } from '@apollo/client'
import { client } from '@blissbook/ui/util/apollo'
import dedent from 'dedent'
import type {
  MutationSendRemindersToMyManagersArgs,
  MutationSendRemindersToMyReportsArgs,
} from './types-and-hooks'

export const queries = dedent`
query managerDashboard ($managerId: Int, $search:String, $isDirect: Boolean! ) {
  manager (managerId: $managerId) {
    personId
    fullName
    preferredName

    managers {
      id
      fullName  
    }
    
    managerStatistics {
      notifyCount
      compliantCount
      nonCompliantCount
      totalCount
    }

    employeeStatistics {
      notifyCount
      compliantCount
      nonCompliantCount
      totalCount
    }
  }
  
  managerReports (managerId: $managerId, search: $search, isDirect: $isDirect) {
    personId
    fullName
    isManager
    isEmployeeSignatureRequired
    isSignatureRequired
    canSendManagerDigest
    canSendEmployeeDigest

    handbooks {
      dueDate
      handbookId
      handbookName
      hasValidSignature
      isSignatureRequired
      lastSignedAt
      handbookUrl
    }

    employeeStatistics {
      notifyCount
      compliantCount
      nonCompliantCount
      totalCount
    }
  }
}

query searchHandbooks($search: String!) {
  searchHandbooks(search: $search) {
    handbookId
    node {
      type
      id
      hash
      title
      languageCode
      texts {
        before
        match
        after
      }
    }
  }
}
`

const SEND_REMINDERS_TO_MY_MANAGERS = gql`
  mutation sendRemindersToMyManagers($managerId: Int, $personIds: [Int!]) {
    sendRemindersToMyManagers(managerId: $managerId, personIds: $personIds)
  }
`

export const sendRemindersToMyManagers = async ({
  managerId,
  personIds,
}: MutationSendRemindersToMyManagersArgs) => {
  return client.mutate({
    mutation: SEND_REMINDERS_TO_MY_MANAGERS,
    variables: { managerId, personIds },
  })
}

const SEND_REMINDERS_TO_MY_REPORTS = gql`
  mutation sendRemindersToMyReports($managerId: Int, $personIds: [Int!]) {
    sendRemindersToMyReports(managerId: $managerId, personIds: $personIds)
  }
`

export const sendRemindersToMyReports = async ({
  managerId,
  personIds,
}: MutationSendRemindersToMyReportsArgs) => {
  return client.mutate({
    mutation: SEND_REMINDERS_TO_MY_REPORTS,
    variables: { managerId, personIds },
  })
}
