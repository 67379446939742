import '@blissbook/ui/util/vendor'
import './vendor'
import './styles'
import { ErrorBoundary } from '@blissbook/ui/lib/error'
import { history } from '@blissbook/ui/util/history'
import { initializeSentry } from '@blissbook/ui/util/integrations/sentry'
import { StoreProvider } from '@blissbook/ui/util/store'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { HotDashboardPage } from '.'
import { createStore } from './store'

const initialState = window.__STATE__
// biome-ignore lint/performance/noDelete: app relies on this behavior
delete window.__STATE__

// initialize sentry
initializeSentry()

const store = createStore(initialState)

// Render the app
ReactDOM.hydrate(
  <ErrorBoundary>
    <StoreProvider store={store}>
      <Router history={history}>
        <HotDashboardPage />
      </Router>
    </StoreProvider>
  </ErrorBoundary>,
  document.getElementById('root'),
)
