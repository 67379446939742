import { getContrastColor } from '@blissbook/common/branding/color'
import { getFirstName, getPreferredName } from '@blissbook/lib/name'
import type { ManagerPersonHandbook } from '@blissbook/ui/application/graph'
import {
  AudienceHeader,
  HeaderLink,
  Layout,
  OrganizationLogo,
} from '@blissbook/ui/dashboard/components'
import {
  type ManagerDashboardQuery,
  useManagerDashboardQuery,
} from '@blissbook/ui/dashboard/graph'
import {
  useAudienceDashboardSettings,
  useManagerSettings,
  useSession,
} from '@blissbook/ui/dashboard/hooks'
import { Button, Input, Link, Modal, Tooltip } from '@blissbook/ui/lib'
import { logUIError } from '@blissbook/ui/util/integrations/sentry'
import { setToast } from '@blissbook/ui/util/toaster'
import { cx } from '@emotion/css'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import pluralize from 'pluralize'
import { useState } from 'react'
import {
  sendRemindersToMyManagers,
  sendRemindersToMyReports,
} from '../../graph/queries'
import {
  getDirectReportRecipientStatus,
  getDocumentsSignNumbers,
} from './helpers'

type ManagersBreadcrumbProps = {
  id: number
  fullName: string
}

export const ManagerDashboardPage = () => {
  const { faviconImage } = useAudienceDashboardSettings()
  const session = useSession()

  const { allowNotificationsToManagers, allowNotificationsToReports } =
    useManagerSettings()
  const allowNotifications =
    allowNotificationsToManagers || allowNotificationsToReports

  const [managerId, setManagerId] = useState<number | null>(null)
  const [managersBreadcrumb, setManagersBreadcrumb] = useState<
    ManagersBreadcrumbProps[]
  >([])
  const [showSearchBox, setShowSearchBox] = useState(false)
  const [search, setSearch] = useState('')

  const isSearching = search.length > 1
  const {
    data,
    loading: isLoading,
    refetch,
  } = useManagerDashboardQuery({
    variables: {
      managerId: search ? undefined : managerId,
      search: isSearching ? search : undefined,
      isDirect: !search,
    },
    onCompleted: ({ manager }) => {
      const { personId: id, fullName } = manager

      if (id === session.person.id || !id) {
        return setManagersBreadcrumb([])
      }

      setManagersBreadcrumb((state) => {
        const idIndex = state.findIndex((manager) => manager.id === id)

        if (idIndex === -1) {
          return [...state, { id, fullName }]
        }

        return state.splice(0, idIndex + 1)
      })
    },
  })

  const handleToggleIsSearching = () => {
    setSearch('')
    setShowSearchBox((state) => !state)
  }

  const handleFilterDirectReports = (value: string) => {
    setSearch(value)
  }

  const handleChangeMainManager = (id: number) => {
    setSearch('')
    setShowSearchBox(false)
    setManagerId(id)
    refetch({
      managerId: id,
    }).catch(logUIError)
  }

  const firstName = session.person ? session.preferredName : ''

  const { primaryColor } = useAudienceDashboardSettings()
  const contrastColor = getContrastColor(primaryColor)

  const currentLevelFirstName = data?.manager
    ? getPreferredName(data.manager)
    : ''
  const { managerReports = [], manager = undefined } = data || {}

  const { managerStatistics = null, employeeStatistics = null } = manager || {}

  return (
    <Layout
      className='tw-flex tw-flex-col lg:tw-h-screen'
      id='manager-dashboard'
      favicon={faviconImage?.url}
    >
      <AudienceHeader>
        <div className='tw-flex tw-items-center tw-justify-between'>
          <OrganizationLogo />

          <HeaderLink to='/'>View documents</HeaderLink>
        </div>
      </AudienceHeader>

      <div
        className='container tw-flex tw-flex-col tw-flex-1 tw-min-h-0 lg:tw-mt-12 tw-pt-4'
        style={{ maxWidth: 800 }}
      >
        <header>
          <h1 className='tw-font-semibold tw-mb-2 tw-text-3xl'>
            {firstName ? `Hello, ${firstName}.` : 'Hello!'}
          </h1>

          <h2 className='tw-text-xl'>
            This is an overview of the signature status of your reports.
          </h2>
        </header>

        <div className='tw-h-fit tw-transition-all'>
          <ReportsHeader
            managerId={managerId}
            managersBreadcrumb={managersBreadcrumb}
            showSearchBox={showSearchBox}
            onToggleIsSearching={handleToggleIsSearching}
            onFilterDirectReports={handleFilterDirectReports}
            onChangeMainManager={handleChangeMainManager}
          />

          <div className='tw-flex tw-flex-col tw-items-center tw-mb-10 tw-justify-between tw-gap-3'>
            {isLoading ? (
              <div className='container tw-flex tw-flex-col tw-items-center tw-justify-center'>
                <h1 className='tw-text-3xl tw-font-semibold tw-mb-4'>
                  Loading...
                </h1>
              </div>
            ) : managerReports.length > 0 ? (
              <>
                {employeeStatistics?.totalCount > 0 && !isSearching && (
                  <div className='tw-w-full tw-flex tw-flex-col tw-gap-4 tw-mb-8'>
                    <header className='tw-relative tw-gap-1 tw-w-full tw-flex tw-flex-col tw-align-center tw-justify-between'>
                      <section className='tw-text-2xl tw-font-semibold tw-text-gray-900 tw-tracking-snug'>
                        {employeeStatistics?.compliantCount.toLocaleString()}{' '}
                        {managersBreadcrumb.length === 0
                          ? `of your ${employeeStatistics?.totalCount.toLocaleString()} reports are compliant.`
                          : `of ${currentLevelFirstName}'s ${employeeStatistics?.totalCount.toLocaleString()} reports are compliant.`}
                      </section>

                      <section>
                        {employeeStatistics?.nonCompliantCount > 0 ? (
                          <p className='tw-text-xl tw-font-medium tw-text-black tw-m-0'>
                            {employeeStatistics?.nonCompliantCount?.toLocaleString()}{' '}
                            of them have not signed their documents.
                          </p>
                        ) : (
                          <p className='tw-font-medium tw-text-black tw-m-0'>
                            {managersBreadcrumb.length === 0
                              ? 'Everyone has signed their documents.'
                              : `All of ${currentLevelFirstName}'s reports have
                          signed their documents.`}
                          </p>
                        )}
                      </section>
                    </header>

                    {allowNotifications && (
                      <section className='tw-w-full tw-flex tw-align-center tw-justify-start tw-gap-2'>
                        {allowNotificationsToManagers &&
                          managerStatistics?.nonCompliantCount > 0 &&
                          managersBreadcrumb.length === 0 && (
                            <Tooltip
                              content='No managers to notify'
                              disabled={managerStatistics?.notifyCount > 0}
                            >
                              <NotifyManagersModal.Button
                                modalProps={{
                                  onComplete: refetch,
                                  title: `Notify your ${pluralize(
                                    'managers',
                                    managerStatistics?.nonCompliantCount,
                                  )}`,
                                  peopleCount:
                                    managerStatistics?.nonCompliantCount,
                                  managerId: managerId || null,
                                }}
                                className={cx(
                                  'tw-px-3 tw-py-2 tw-rounded-md tw-cursor-pointer tw-text-sm tw-inline-flex tw-items-center tw-font-semibold tw-m-0 tw-gap-2 hover:tw-opacity-90 tw-transition-opacity',
                                  'disabled:tw-cursor-not-allowed disabled:tw-opacity-50 disabled:hover:tw-opacity-40',
                                )}
                                disabled={managerStatistics?.notifyCount === 0}
                                style={{
                                  backgroundColor: primaryColor,
                                  color: contrastColor,
                                }}
                              >
                                <FontAwesomeIcon icon={['far', 'bell']} />
                                Notify your managers
                              </NotifyManagersModal.Button>
                            </Tooltip>
                          )}

                        {allowNotificationsToReports &&
                          employeeStatistics?.nonCompliantCount > 0 && (
                            <Tooltip
                              content='No employees to notify'
                              disabled={employeeStatistics?.notifyCount > 0}
                            >
                              <NotifyReportsModal.Button
                                modalProps={{
                                  onComplete: refetch,
                                  title: 'Notify non-compliant reports',
                                  managerId: managerId || null,
                                  peopleCount:
                                    employeeStatistics?.nonCompliantCount,
                                }}
                                disabled={employeeStatistics?.notifyCount === 0}
                                className={cx(
                                  'tw-px-3 tw-py-2 tw-rounded-md tw-cursor-pointer tw-text-sm tw-inline-flex tw-items-center tw-font-semibold tw-m-0 tw-gap-2 hover:tw-opacity-90 tw-transition-opacity',
                                  'disabled:tw-cursor-not-allowed disabled:tw-opacity-50 disabled:hover:tw-opacity-40',
                                )}
                                style={{
                                  backgroundColor: primaryColor,
                                  color: contrastColor,
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={['far', 'paper-plane']}
                                />
                                Notify{' '}
                                {employeeStatistics?.nonCompliantCount?.toLocaleString()}{' '}
                                non-compliant{' '}
                                {pluralize(
                                  'reports',
                                  employeeStatistics?.nonCompliantCount,
                                )}
                              </NotifyReportsModal.Button>
                            </Tooltip>
                          )}
                      </section>
                    )}
                  </div>
                )}

                {managerReports.map((managerReport) => (
                  <ReportView
                    key={managerReport.personId}
                    managerId={managerId}
                    onComplete={refetch}
                    managerReport={managerReport}
                    onChangeMainManager={handleChangeMainManager}
                  />
                ))}
              </>
            ) : (
              <EmptyReportView />
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

type ReportsHeaderProps = {
  managerId: number
  managersBreadcrumb: ManagersBreadcrumbProps[]
  showSearchBox: boolean
  onToggleIsSearching: () => void
  onFilterDirectReports: (value: string) => void
  onChangeMainManager: (id: number) => void
}

const ReportsHeader = ({
  managerId,
  managersBreadcrumb,
  showSearchBox,
  onToggleIsSearching,
  onFilterDirectReports,
  onChangeMainManager,
}: ReportsHeaderProps) => {
  const session = useSession()

  if (showSearchBox) {
    return (
      <header className='tw-flex tw-items-center tw-border-b tw-py-3 tw-my-4 tw-gap-2'>
        <Input
          autoFocus
          className='tw-w-full tw-border-gray-500 tw-border-2 tw-rounded-md tw-py-2 tw-px-3'
          placeholder="Filter by person's name"
          type='search'
          debounce={500}
          onChangeValue={onFilterDirectReports}
        />

        <button
          type='button'
          className='tw-capitalize tw-whitespace-nowrap tw-text-gray-700 tw-p-2 tw-opacity-50 hover:tw-opacity-100 tw-transition-all'
          onClick={onToggleIsSearching}
        >
          Remove filter
        </button>
      </header>
    )
  }

  const isArrayBiggerThanTwo = managersBreadcrumb.length > 2

  const sliceStart = isArrayBiggerThanTwo ? managersBreadcrumb.length - 2 : 0
  const sliceEnd = isArrayBiggerThanTwo ? managersBreadcrumb.length : 2

  const managersArrayToDisplay = managersBreadcrumb.slice(sliceStart, sliceEnd)

  const isYourReport = Boolean(managerId === session.person.id) || !managerId

  const handleClearBreadcrumb = () => {
    onChangeMainManager(null)
  }

  const searchEl = (
    <Tooltip content='Search all of your reports'>
      <button onClick={onToggleIsSearching} type='button'>
        <FontAwesomeIcon
          icon='search'
          className='tw-text-gray-600 tw-text-2xl tw-transition-all hover:tw-text-black'
        />
      </button>
    </Tooltip>
  )

  if (isYourReport) {
    return (
      <header className='tw-flex tw-items-right tw-border-b tw-my-4 tw-py-3'>
        {searchEl}
      </header>
    )
  }

  return (
    <header className='tw-my-4 tw-flex tw-flex-col tw-gap-0'>
      <button
        type='button'
        className='tw-flex tw-items-center tw-text-black tw-transition-all tw-w-max tw-py-3 hover:tw-text-gray-600'
        onClick={handleClearBreadcrumb}
      >
        <FontAwesomeIcon icon='arrow-left' />
        <span className='tw-ml-2'>Go back to your reports</span>
      </button>
      <div className='tw-flex tw-items-center tw-justify-between tw-border-b tw-border-gray-300 tw-py-3'>
        <section className='tw-flex tw-items-center tw-gap-3'>
          <button
            type='button'
            className='tw-flex tw-items-center tw-bg-gray-50 tw-px-2 tw-py-1 tw-rounded-sm tw-text-black tw-transition-all hover:tw-bg-gray-200'
            onClick={handleClearBreadcrumb}
          >
            <FontAwesomeIcon icon={['fas', 'user-crown']} />
            <span className='tw-ml-2'>You</span>
          </button>

          {isArrayBiggerThanTwo ? (
            <div className='tw-flex tw-items-center tw-gap-2'>
              <FontAwesomeIcon icon='chevron-right' />

              <p className='captalize tw-mb-0 tw-font-semibold tw-text-black'>
                ...
              </p>
            </div>
          ) : null}

          {managersArrayToDisplay.map((manager, index) =>
            index !== managersArrayToDisplay.length - 1 ? (
              <button
                type='button'
                className='tw-flex tw-items-center tw-gap-2'
                onClick={() => onChangeMainManager(manager.id)}
                key={manager.id}
              >
                <FontAwesomeIcon icon='chevron-right' />

                <p className='captalize tw-mb-0 tw-font-semibold tw-text-black tw-bg-gray-50 tw-px-2 tw-py-1 tw-rounded-sm hover:tw-bg-gray-200'>
                  {manager.fullName.trim()}
                </p>
              </button>
            ) : (
              <div
                className='tw-flex tw-items-center tw-gap-2'
                key={manager.id}
              >
                <FontAwesomeIcon icon='chevron-right' />

                <p className='captalize tw-mb-0 tw-font-semibold tw-text-black'>
                  {manager.fullName.trim()}'s direct reports
                </p>
              </div>
            ),
          )}
        </section>

        {searchEl}
      </div>
    </header>
  )
}

type ReportsViewProps = {
  managerId: number | null
  managerReport: ManagerDashboardQuery['managerReports'][number]
  onChangeMainManager: (id: number) => void
  onComplete: () => void
}

const ReportView = ({
  managerId,
  managerReport,
  onChangeMainManager,
  onComplete,
}: ReportsViewProps) => {
  const { allowNotificationsToManagers, allowNotificationsToReports } =
    useManagerSettings()
  const allowNotifications =
    allowNotificationsToManagers || allowNotificationsToReports

  const { primaryColor } = useAudienceDashboardSettings()
  const contrastColor = getContrastColor(primaryColor)

  const {
    canSendManagerDigest,
    employeeStatistics,
    fullName,
    handbooks,
    isEmployeeSignatureRequired,
    isSignatureRequired,
    canSendEmployeeDigest,
    isManager,
    personId,
  } = managerReport

  const [showDocuments, setShowDocuments] = useState(false)

  const docSignatures = getDocumentsSignNumbers(handbooks)

  pluralize.addPluralRule(/need$/i, 'need')
  pluralize.addSingularRule(/need$/i, 'needs')

  const handleToggleShowDocuments = () => {
    setShowDocuments((state) => !state)
  }

  return (
    <div className='tw-w-full last:tw-border-0 tw-shadow-card tw-rounded tw-bg-white tw-border-gray-400 hover:tw-bg-gray-50 tw-transition-all'>
      <button
        type='button'
        className={`tw-flex tw-items-center tw-justify-between tw-w-full tw-px-6 tw-pt-6 tw-transition-all ${
          showDocuments ? 'tw-pb-2' : 'tw-pb-6'
        }`}
        onClick={handleToggleShowDocuments}
      >
        <section className='tw-flex tw-items-center tw-w-full sm:tw-w-auto tw-justify-start tw-gap-3'>
          <Tooltip
            content={
              docSignatures.unsigned.overdue > 0 ? (
                <p className='tw-mb-0'>
                  {pluralize('document', docSignatures.unsigned.overdue, true)}{' '}
                  haven't been signed yet.
                </p>
              ) : (
                <p className='tw-mb-0'>All documents have been signed.</p>
              )
            }
          >
            <span
              className={`tw-flex tw-flex-shrink-0 tw-w-2 tw-h-2 tw-rounded-full ${
                docSignatures.unsigned.total > 0
                  ? 'tw-bg-red-700'
                  : 'tw-bg-green-700'
              }`}
            />
          </Tooltip>

          <Tooltip content={`Employee ID: ${personId}`} disabled={!personId}>
            <p className='tw-m-0 tw-text-xl tw-text-start tw-font-semibold'>
              {fullName}
            </p>
          </Tooltip>
        </section>

        <span className='tw-flex tw-items-center tw-justify-end'>
          {docSignatures.unsigned.total > 0 && (
            <p className='tw-mb-0'>
              {pluralize('document', docSignatures.unsigned.total, true)}{' '}
              {pluralize('need', docSignatures.unsigned.total)} attention
            </p>
          )}

          <FontAwesomeIcon
            icon='chevron-down'
            className={`tw-ml-2 tw-text-black tw-transition-all ${
              showDocuments ? 'tw-rotate-180' : ''
            }`}
          />
        </span>
      </button>

      {showDocuments && (
        <div>
          {handbooks?.map((handbook) => (
            <HandbookRecipients
              key={handbook.handbookId}
              personFullName={fullName}
              personId={personId}
              handbook={handbook}
            />
          ))}
        </div>
      )}

      {isManager ? (
        <>
          <hr className='tw-m-0' />

          <section className='tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-between tw-w-full tw-py-3 tw-px-6 tw-gap-4 md:tw-gap-0'>
            <div className='tw-flex tw-items-center tw-gap-2'>
              {employeeStatistics?.nonCompliantCount > 0 ? (
                <>
                  <FontAwesomeIcon
                    icon={['far', 'circle-exclamation']}
                    className='tw-text-red-700 tw-w-4 t-h-4'
                  />

                  <p className='tw-m-0'>
                    {employeeStatistics?.nonCompliantCount.toLocaleString()} of{' '}
                    {employeeStatistics?.totalCount.toLocaleString()} reports{' '}
                    {pluralize('need', employeeStatistics?.nonCompliantCount)}{' '}
                    attention
                  </p>
                </>
              ) : (
                <>
                  <FontAwesomeIcon
                    icon={['far', 'check']}
                    className='tw-text-green-700 tw-w-4 t-h-4'
                  />

                  <p className='tw-m-0'>All reports are compliant</p>
                </>
              )}
            </div>

            <button
              type='button'
              className='tw-px-3 tw-py-2 tw-rounded-md tw-text-sm tw-inline-flex tw-items-center tw-font-semibold tw-m-0 tw-gap-2 hover:tw-opacity-90 tw-transition-all'
              onClick={() => onChangeMainManager(personId)}
              style={{
                backgroundColor: primaryColor,
                color: contrastColor,
              }}
            >
              Show direct reports
              <FontAwesomeIcon icon='chevron-right' />
            </button>
          </section>
        </>
      ) : null}

      {allowNotifications && (
        <section className='tw-w-full tw-flex tw-align-center tw-justify-start tw-pb-3 tw-px-6 tw-gap-4'>
          {allowNotificationsToManagers &&
            isManager &&
            isEmployeeSignatureRequired && (
              <Tooltip
                content={`You can't send a notification to ${fullName} about their reports`}
                disabled={canSendManagerDigest}
              >
                <NotifyManagersModal.Button
                  modalProps={{
                    onComplete,
                    title: 'Notify this manager',
                    managerId,
                    personIds: [personId],
                  }}
                  className={cx(
                    'tw-px-3 tw-py-1.5 tw-cursor-pointer tw-rounded-md tw-border-solid tw-border-2 tw-bg-white tw-text-xs tw-inline-flex tw-items-center tw-gap-2 hover:tw-opacity-90 tw-transition-opacity',
                    'disabled:tw-cursor-not-allowed disabled:tw-opacity-50 disabled:hover:tw-opacity-40',
                  )}
                  disabled={!canSendManagerDigest}
                  style={{
                    backgroundColor: contrastColor,
                    borderColor: primaryColor,
                    outlineColor: primaryColor,
                    color: primaryColor,
                  }}
                >
                  <FontAwesomeIcon icon={['far', 'bell']} />
                  Notify {fullName}
                </NotifyManagersModal.Button>
              </Tooltip>
            )}

          {allowNotificationsToReports &&
            isManager &&
            isEmployeeSignatureRequired && (
              <Tooltip
                content='No employees to notify'
                disabled={employeeStatistics?.notifyCount > 0}
              >
                <NotifyReportsModal.Button
                  modalProps={{
                    onComplete,
                    title: `Remind ${
                      employeeStatistics?.nonCompliantCount
                    } ${pluralize(
                      'of your reports',
                      employeeStatistics?.nonCompliantCount,
                    )}`,
                    managerId: personId,
                    peopleCount: employeeStatistics?.nonCompliantCount,
                  }}
                  disabled={employeeStatistics?.notifyCount === 0}
                  className={cx(
                    'tw-px-3 tw-py-1.5 tw-cursor-pointer tw-rounded-md tw-border-solid tw-border-2 tw-text-xs tw-inline-flex tw-items-center tw-gap-2 hover:tw-bg-gray-100 hover:tw-opacity-90 tw-transition-all',
                    'disabled:tw-cursor-not-allowed disabled:tw-opacity-50 disabled:hover:tw-opacity-40',
                  )}
                  style={{
                    backgroundColor: contrastColor,
                    borderColor: primaryColor,
                    outlineColor: primaryColor,
                    color: primaryColor,
                  }}
                >
                  <FontAwesomeIcon icon={['far', 'paper-plane']} />
                  Send reminder to{' '}
                  {employeeStatistics?.nonCompliantCount?.toLocaleString()}{' '}
                  {pluralize('people', employeeStatistics?.nonCompliantCount)}
                </NotifyReportsModal.Button>
              </Tooltip>
            )}

          {allowNotificationsToReports && isSignatureRequired && (
            <Tooltip
              content={`You can't send a reminder to ${fullName}`}
              disabled={canSendEmployeeDigest}
            >
              <NotifyReportsModal.Button
                modalProps={{
                  onComplete,
                  title: `Send Reminder to ${fullName}`,
                  managerId: null,
                  personIds: [personId],
                }}
                className={cx(
                  'tw-px-3 tw-py-1.5 tw-cursor-pointer tw-rounded-md tw-border-solid tw-border-2 tw-text-xs tw-inline-flex tw-items-center tw-gap-2 hover:tw-bg-gray-100 hover:tw-opacity-90 tw-transition-all',
                  'disabled:tw-cursor-not-allowed  disabled:tw-opacity-50 disabled:hover:tw-opacity-40',
                )}
                disabled={!canSendEmployeeDigest}
                style={{
                  backgroundColor: contrastColor,
                  borderColor: primaryColor,
                  outlineColor: primaryColor,
                  color: primaryColor,
                }}
              >
                <FontAwesomeIcon icon={['far', 'paper-plane']} />
                Send reminder to {fullName}
              </NotifyReportsModal.Button>
            </Tooltip>
          )}
        </section>
      )}
    </div>
  )
}

const EmptyReportView = () => (
  <div className='tw-py-6 tw-flex tw-items-center tw-flex-col tw-w-full last:tw-border-0 tw-shadow-card tw-rounded tw-bg-white tw-border-gray-400 hover:tw-bg-gray-50 tw-transition-all'>
    <h2 className='tw-font-semibold'>Oops.</h2>
    <p>We found no direct reports.</p>
    <p>Please try again</p>
  </div>
)

type HandbookRecipientsProps = {
  personFullName: string
  personId: number
  handbook: ManagerPersonHandbook
}

const HandbookRecipients = ({
  personFullName,
  personId,
  handbook,
}: HandbookRecipientsProps) => {
  const { primaryColor } = useAudienceDashboardSettings()
  const contrastColor = getContrastColor(primaryColor)

  const status = getDirectReportRecipientStatus(handbook)

  const firstName = getFirstName(personFullName)
  const signedStatus = status?.formatTooltip(handbook)

  return (
    <div className='tw-flex tw-flex-col tw-gap-4 md:tw-grid tw-grid-cols-5 tw-items-center tw-py-1 tw-px-6 md:tw-pl-14 last:tw-pb-6'>
      <div className='tw-col-span-4 tw-flex tw-align-center tw-justify-between'>
        <section className='tw-flex tw-justify-between tw-items-baseline tw-gap-2'>
          <div className='tw-flex tw-items-center tw-text-base'>
            <span className={cx('tw-block tw-w-5', status?.className)}>
              <FontAwesomeIcon icon={status?.icon as IconProp} />
            </span>

            <p className='tw-text-gray-800 tw-ml-4 tw-m-0'>
              {handbook.handbookName}
            </p>
          </div>

          <small className='tw-text-xs tw-text-center tw-text-black-faded tw-whitespace-nowrap'>
            {signedStatus}
          </small>
        </section>
      </div>

      <div className='tw-flex tw-justify-end tw-ml-auto tw-w-full'>
        <Tooltip content={`View ${firstName}'s ${handbook.handbookName}`}>
          <span>
            <Link
              className='tw-px-3 tw-py-1.5 tw-rounded-md tw-border-solid tw-border-2 tw-text-sm tw-inline-flex tw-items-center tw-font-semibold hover:tw-ring-1 tw-transition-all'
              href={`${handbook.handbookUrl}?person=${personId}`}
              target='_blank'
              style={{
                backgroundColor: contrastColor,
                borderColor: primaryColor,
                outlineColor: primaryColor,
                color: primaryColor,
              }}
            >
              <FontAwesomeIcon className='tw-mr-2' icon={['far', 'eye']} />
              <span className='tw-font-semibold'>View</span>
            </Link>
          </span>
        </Tooltip>
      </div>
    </div>
  )
}

type ManagerModalProps = {
  managerId: number | null
  personIds?: number[]
  peopleCount?: number
  title: string
  onComplete: () => void
}

const NotifyManagersModal = Modal.wrap<ManagerModalProps>(
  ({ onClose, onComplete, managerId, personIds, peopleCount = 1, title }) => {
    const { primaryColor } = useAudienceDashboardSettings()
    const contrastColor = getContrastColor(primaryColor)

    const handleNotifyManagers = async () => {
      const { data } = await sendRemindersToMyManagers({
        managerId,
        personIds,
      })

      setToast(
        `An email has been sent to notify ${pluralize(
          'manager',
          data.sendRemindersToMyManagers,
          true,
        )}`,
      )
      onComplete()
      onClose()
    }

    return (
      <Modal.Component
        position='center'
        onClose={onClose}
        width={450}
        className='tw-rounded-2xl'
      >
        <Modal.Content className='tw-rounded-2xl tw-p-6'>
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p className='tw-text-black'>
              {peopleCount > 1
                ? 'Managers who report to you will be notified that some of their direct reports have not signed their documents yet.'
                : 'This manager will be notified that some of their direct reports have not signed their documents yet.'}
            </p>

            <div className='form-submit tw-justify-start tw-gap-2'>
              <Button
                onClick={handleNotifyManagers}
                className='tw-px-3 tw-py-2 tw-rounded-md tw-text-sm tw-inline-flex tw-items-center tw-font-semibold tw-m-0 tw-gap-2 hover:tw-opacity-90 tw-transition-all'
                style={{
                  backgroundColor: primaryColor,
                  color: contrastColor,
                }}
              >
                <FontAwesomeIcon icon={['far', 'paper-plane']} />
                Send {peopleCount} {pluralize('notification', peopleCount)}{' '}
                {pluralize('email', peopleCount)}
              </Button>

              <Button
                onClick={onClose}
                className='tw-px-3 tw-py-2 tw-rounded-md tw-text-black-disabled tw-text-sm tw-inline-flex tw-items-center tw-font-semibold tw-m-0 tw-gap-2 hover:tw-opacity-90 tw-transition-all'
              >
                Cancel
              </Button>
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Component>
    )
  },
)

const NotifyReportsModal = Modal.wrap<ManagerModalProps>(
  ({ onClose, onComplete, managerId, personIds, peopleCount, title }) => {
    const { primaryColor } = useAudienceDashboardSettings()
    const contrastColor = getContrastColor(primaryColor)

    const handleRemindingEmployees = async () => {
      const { data } = await sendRemindersToMyReports({
        managerId,
        personIds,
      })
      setToast(
        `A reminder email has been sent to ${pluralize(
          'employee',
          data.sendRemindersToMyReports,
          true,
        )}`,
      )

      onComplete()
      onClose()
    }

    return (
      <Modal.Component
        position='center'
        onClose={onClose}
        width={450}
        className='tw-rounded-2xl'
      >
        <Modal.Content className='tw-rounded-2xl tw-p-6'>
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p className='tw-text-black'>
              They will receive an email coming from you reminding them to sign
              their documents.
            </p>

            <div className='form-submit tw-justify-start tw-gap-2'>
              <Button
                onClick={handleRemindingEmployees}
                className='tw-px-3 tw-py-2 tw-rounded-md tw-text-sm tw-inline-flex tw-items-center tw-font-semibold tw-m-0 tw-gap-2 hover:tw-opacity-90 tw-transition-all'
                style={{
                  backgroundColor: primaryColor,
                  color: contrastColor,
                }}
              >
                <FontAwesomeIcon icon={['far', 'paper-plane']} />
                Send {peopleCount} {pluralize('reminder', peopleCount)}{' '}
                {pluralize('email', peopleCount)}
              </Button>

              <Button
                onClick={onClose}
                className='tw-px-3 tw-py-2 tw-rounded-md tw-text-black-disabled tw-text-sm tw-inline-flex tw-items-center tw-font-semibold tw-m-0 tw-gap-2 hover:tw-opacity-90 tw-transition-all'
              >
                Cancel
              </Button>
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Component>
    )
  },
)
